<template>
  <div v-if="booking" class="form-container pb-6">
    <div v-if="error">{{ $t('text.anErrorOccurredWhileLoading') }}</div>
    <template v-else>
      <UsageHistoryItem
        :id="booking.id"
        :urlImg="facility.mainImageUrl"
        :title="facility.name"
        :checkInDate="booking.checkInDate"
        :checkOutDate="booking.checkOutDate"
        :roomTypeName="booking.room.roomType.name"
        isActive
        class="px-0"
      />
      <div class="text-medium --prm text-center font-weight-bold pb-3">
        {{ $t('text.preCheckInInfo') }}
      </div>
      <v-form ref="form">
        <div
          v-for="(guest, ind) in booking.guests"
          :key="guest.id">
          <GuestInfoInput
            class="mb-7"
            preCheckIn
            topLevel
            :guest="guest"
            :representative="guest.representative"
            :index="ind + 1"
            @change="(newGuest) => guestChange(ind, newGuest)"
            :bookingId="id"
            :bookingCode="code"
            :bookingTypeId="booking.bookingTypeId"
          />
        </div>
        <v-btn
          elevation="0"
          rounded
          block
          x-large
          @click="save"
          class="text-medium --white"
          color="btn-grad--orange"
        >
          {{ $t('buttons.save') }}
        </v-btn>
      </v-form>
    </template>
  </div>
</template>

<script>
import UsageHistoryItem from '../../../../components/UsageHistoryItem'
import GuestInfoInput from '../../../../components/ReservationComponents/GuestInfoInput.vue'

export default {
  name: 'PreChekInInformation',
  components: {
    GuestInfoInput,
    UsageHistoryItem
  },
  async mounted () {
    await this.$doLoading(async () => {
      try {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('loadBookingDetail', {
            id: this.id,
            code: this.code
          })
        })
      } catch (e) {
        this.error = true
      }
    })
  },
  computed: {
    id () {
      if (this.$route.params.id) return Number(this.$route.params.id)
      if (this.$route.query.id) return Number(this.$route.query.id)
      return null
    },
    code () {
      return this.$route.query.code
    },
    booking () {
      return this.$store.getters.selectedBooking
    },
    facility () {
      return this.booking?.room.roomType.facility ?? {}
    },
    productTypeId () {
      return this.booking.contract?.productTypeId
    }
  },
  methods: {
    guestChange (ind, guest) {
      this.$store.commit('updateGuestOfSelectedBooking', {
        ind, guest
      })
    },
    async save () {
      if (this.$refs.form.validate()) {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            await this.$store.dispatch('preCheckIn', {
              id: this.id,
              code: this.code,
              guests: this.booking.guests
            })
          })
          this.$toast.success(this.$t('text.preCheckInfoHasBeenSaved').toString())
        })
      }
    },
    getIndexOf (current, index) {
      return this.booking.guests
        .filter((g, ind) =>
          ind < index &&
          g.child === current.child &&
          g.bedShared === current.bedShared
        ).length + 1
    }
  },
  data () {
    return {
      error: false
    }
  }
}
</script>
