<template>
  <div :class="topLevel ? 'bg-w-silver pb-8' : ''">
    <template v-if="readonly">
      <div class="text-title --red">
        ※同日に複数のお部屋を予約する場合、1部屋は会員様のご宿泊が必要です。
      </div>
    </template>
    <div
      class="text-heading font-weight-bold pt-3"
      :class="topLevel ? 'bg-orange text-center --white text-title font-weight-bold py-3' : ''"
    >
      {{ label }}
    </div>
    <div class="mt-3" :class="topLevel ? 'px-4' : ''">
      <template v-if="!nameFixed">
        <FormLabel for="name" :label="$t('reservationAndUsageHistory.guestName')" required />
        <template v-if="readonly">
          <v-checkbox :input-value="changeRepWarned" @change="onChangeRepWarnedChange"
            label="会員ご本人様が利用しないお部屋を予約する"
          />
        </template>
        <v-text-field
          id="name"
          class="mt-2 rounded-lg"
          :rules="[$rules.required]"
          :disabled="readonly && !changeRepWarned"
          hide-details
          v-model="localGuest.name"
          solo
          flat
          outlined
        />
      </template>
      <template v-else>
        <span class="text-title --w-bold">
            {{ localGuest.name || this.$t("common.leaveNameUnset") }}
          </span>
        <v-divider class="my-4 bg-dark-silver"></v-divider>
      </template>
      <template v-if="preCheckIn">
        <FormLabel for="address1" :label="$t('common.residence')" />
        <v-text-field
          id="address1"
          class="mt-2 rounded-lg"
          hide-details
          :disabled="readonly && !changeRepWarned"
          v-model="localGuest.address1"
          solo
          flat
          outlined
        />
        <FormLabel for="profession" :label="$t('common.profession')" />
        <v-text-field
          id="profession"
          class="mt-2 rounded-lg"
          hide-details
          v-model="localGuest.occupation"
          solo
          flat
          outlined
        />
        <FormLabel for="age" :label="$t('common.age')" />
        <div style="width: 150px;">
          <v-text-field
            id="age"
            class="mt-2 rounded-lg text-field-outer-field"
            type="number"
            hide-details="auto"
            :value="localGuest.age"
            :rules="[rules.age]"
            @input="updateAge"
            solo
            flat
            outlined
            min="0"
          >
            <template v-slot:append-outer>
              <div class="text-normal">{{ $t("common.only") }}</div>
            </template>
          </v-text-field>
        </div>
        <FormLabel :label="$t('common.sex')" />
        <GenderRadio v-model="localGuest.sex" />
        <FormLabel for="phone" :label="$t('common.phone')" />
        <v-text-field
          id="phone"
          class="mt-2 rounded-lg"
          type="tel"
          hide-details
          v-model="localGuest.tel"
          solo
          flat
          outlined
        />
        <FormLabel for="email" :label="$t('common.email')" />
        <v-text-field
          id="email"
          class="mt-2 rounded-lg"
          type="email"
          v-model="localGuest.email"
          :rules="[$rules.email]"
          solo
          flat
          outlined
        />
        <FormLabel for="countryOfCitizenship" :label="$t('common.countryOfCitizenship')" />
        <Nationality id="countryOfCitizenship" v-model="localGuest.nationality" hide-details />

        <div class="pb-3" v-show="showPassportFields">
          <div class="text-center my-3">
            <div v-if="passportPhotoUrl" >
              <a :href="passportPhotoUrl" target="_blank">
                <v-icon color="primary">mdi-check</v-icon>パスポートアップロード済 <IconOpen/>
              </a>
            </div>
            <v-btn
              elevation="0"
              height="50"
              color="primary"
              rounded
              @click="uploadPassportPhoto"
            >{{ passportPhotoUrl ? 'パスポートを再アップロード' : $t('text.uploadYourPassport') }}</v-btn>
            <input
              v-show="false"
              type="file"
              ref="passportPhotoFile"
              accept="image/*"
              capture="camera"
              @change="passportPhotoChange"
            />
          </div>
          <FormLabel for="passportNumber" :label="$t('common.passportNumber')" :disabled="!showPassportFields" />
          <v-text-field
            id="passportNumber"
            :disabled="!showPassportFields"
            class="mt-2 rounded-lg"
            type="text"
            hide-details
            v-model="localGuest.passportNumber"
            solo
            flat
            outlined
          />
        </div>
      </template>
    </div>
    <CommonModal
      :visible="changeRepWarningDialog"
      :is-persistent="true"
      @close="cancelRepChange"
    >
      <div class="text-left">同日に複数のお部屋を予約する場合、1部屋は会員様のご宿泊が必要です。<br>宿泊代表者の名前を変更しますか？</div>
      <div class="mt-3">
        <v-btn elevation="0" rounded @click="confirmRepChange" color="primary" class="mr-3">Yes</v-btn>
        <v-btn elevation="0" rounded @click="cancelRepChange" color="secondary" class="ml-3">NO</v-btn>
      </div>
    </CommonModal>
  </div>
</template>

<script>
import CommonModal from '@/components/Modal/Common'
import FormLabel from '../Inputs/FormLabel.vue'
import GenderRadio from '../Inputs/GenderRadio.vue'
import Nationality from '../Inputs/Nationality.vue'
import { uploadPassportFile } from '@/api/file'
import IconOpen from '../icons/IconOpen.vue'
import { getAgeFromDate } from '@/utils/get-date'
import { isNew2022BookingType } from '@/constants/booking-type'

export default {
  components: {
    FormLabel,
    GenderRadio,
    Nationality,
    IconOpen,
    CommonModal
  },
  name: 'GuestInfoInput',
  props: {
    guest: Object,
    representative: Boolean,
    index: Number,
    preCheckIn: Boolean,
    nameFixed: Boolean,
    topLevel: Boolean,
    bookingId: {
      type: Number,
      required: false
    },
    bookingCode: {
      type: String,
      required: false
    },
    isFirstBooking: {
      type: Boolean,
      default: null
    },
    bookingTypeId: Number
  },
  data () {
    return {
      localGuest: {},
      passportFileUrl: null,
      rules: {
        age: value => (!value || value >= 0) || this.$t('rules.invalidAge')
      },
      changeRepWarned: false,
      changeRepWarningDialog: false
    }
  },
  async mounted () {
    // if readonly set the name and address from the regsitered data.
    await this.$store.dispatch('loadUserInfo')

    if (this.readonly) {
      if (this.localGuest.name && this.localGuest.name !== this.$store.state.user.userInfo.name) {
        // the rep name has been changed already, which means the user has been warned of the change rep restriction
        this.changeRepWarned = true
      }

      const newGuest = { ...this.localGuest }

      newGuest.name = newGuest.name || this.$store.state.user.userInfo.name
      newGuest.address1 = newGuest.address1 || this.$store.state.user.userInfo.address
      newGuest.email = newGuest.email || this.$store.state.user.userInfo.email
      newGuest.tel = newGuest.tel || this.$store.state.user.userInfo.tel
      newGuest.sex = newGuest.sex || this.$store.state.user.userInfo.sex

      const bday = this.$store.state.user.userInfo.birthday
      if (bday) {
        newGuest.age = getAgeFromDate(bday)
      }

      this.localGuest = newGuest
    }
  },
  watch: {
    guest: {
      immediate: true,
      handler (newGuest) {
        if (newGuest) {
          this.localGuest = newGuest
        }
      }
    },
    localGuest: {
      deep: true,
      handler () {
        this.emitChange()
      }
    }
  },
  computed: {
    name () {
      return ''
    },
    label () {
      if (this.representative) return this.$t('text.lodgingRepresentative')
      if (!this.guest.child) return `${this.$t('common.adult')}${this.index}`
      if (this.guest.bedShared) return `${this.$t('common.childSleepWith')} ${this.index}`
      return `${this.$t('common.childSleepWithOut')} ${this.index}`
    },
    showPassportFields () {
      return this.localGuest.nationality === this.$t('common.other')
    },
    passportPhotoUrl () {
      // use the locally uploaded file url if there is
      // then return the file already uploaded previously
      return this.passportFileUrl || this.localGuest.passportPhotoUrl
    },
    readonly () {
      return this.isFirstBooking && this.representative && (
        this.$isFamilyUser() ||
        (this.$isSubUser() && !this.$isCompanyUser()) ||
        (this.$isMainUser() && !this.$isCompanyUser() && isNew2022BookingType(this.bookingTypeId))
      )
    }
  },
  methods: {
    uploadPassportPhoto () {
      this.$refs.passportPhotoFile.click()
    },
    async passportPhotoChange (event) {
      if (event.target.files && event.target.files.length) {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            const { key, fileUrl } = await uploadPassportFile(event.target.files[0], {
              bookingId: this.bookingId,
              code: this.bookingCode
            })
            this.passportFileUrl = fileUrl
            this.localGuest.passportPhoto = key
          })
        })
      }
    },
    emitChange () {
      this.$emit('change', this.localGuest)
    },
    updateAge (age) {
      age = Number(age)
      this.localGuest.age = age <= 0 ? 0 : age
    },
    setDefaultGuest () {
      const newGuest = { ...this.localGuest }
      newGuest.name = this.$store.state.user.userInfo.name
      newGuest.address1 = this.$store.state.user.userInfo.address
      newGuest.email = this.$store.state.user.userInfo.email
      newGuest.tel = this.$store.state.user.userInfo.tel
      newGuest.sex = this.$store.state.user.userInfo.sex

      const bday = this.$store.state.user.userInfo.birthday
      if (bday) {
        newGuest.age = getAgeFromDate(bday)
      }

      this.localGuest = newGuest
    },
    onChangeRepWarnedChange (v) {
      if (v) {
        // warn user of the restriction
        this.changeRepWarningDialog = true

        // we have to set it true then false to force the checkbox to be unchecked
        this.changeRepWarned = true
        this.$nextTick(() => {
          this.changeRepWarned = false
        })
      } else {
        this.changeRepWarned = false
        this.setDefaultGuest()
      }
    },
    cancelRepChange () {
      this.changeRepWarned = false
      this.changeRepWarningDialog = false
    },
    confirmRepChange () {
      this.localGuest.name = ''
      this.localGuest.address1 = ''
      this.localGuest.email = null
      this.localGuest.tel = ''
      this.localGuest.sex = null
      this.localGuest.age = null

      this.changeRepWarned = true
      this.changeRepWarningDialog = false
    }
  }
}
</script>
