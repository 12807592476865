<template>
  <v-select
    :id="id"
    :items="items"
    :value="value"
    solo
    flat
    outlined
    item-value="value"
    item-text="text"
    v-bind="$attrs"
    v-on="$listeners"
    class="rounded-lg"
  />
</template>

<script>
export default {
  props: {
    value: String,
    id: {
      type: String,
      required: false
    }
  },
  computed: {
    items () {
      return [
        { text: this.$t('common.japanNationalities'), value: this.$t('common.japan') },
        { text: this.$t('common.otherNationalities'), value: this.$t('common.other') }
      ]
    }
  }
}
</script>
